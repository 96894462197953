import React, { useState } from 'react'
import './Tile.css'
import '../App.css'
import { TileConfig } from '../TileConfig'
import { click } from '@testing-library/user-event/dist/click'

const Tile = ({ id, onTileClick, clickedTile }) => {
    const [hover, setHover] = useState(false);
    const [rotationState, setRotationState] = useState(0);
    const [tileClicked, setTileClicked] = useState(false);
    const tileConfig = TileConfig.configs[id];

    function onMouseOver() {
        if (!hover) {
            setHover(true);
        }
    }

    function onMouseLeave() {
        if (hover) {
            setHover(false);
        }
    }

    function rotateRight() {
        setTileClicked(!tileClicked);
        if (rotationState === 3) {
            setRotationState(0);
        }
        else {
            setRotationState(rotationState + 1);
        }
    }

    function rotateLeft() {
        setTileClicked(!tileClicked);
        if (rotationState === 0) {
            setRotationState(3);
        }
        else {
            setRotationState(rotationState - 1);
        }
    }

    function handleTileClick() {
        console.log(clickedTile)
        var forceFalse = false;

        if (clickedTile === null && tileClicked) {
            setTileClicked(false);
            console.log("Flip!!!")
            forceFalse = true;
        }
        else if (clickedTile !== null && tileClicked) {
            setTileClicked(false);
            console.log("Flip!!!")
            forceFalse = true;
        }

        if (!forceFalse && tileClicked) {
            setTileClicked(false);
            if (onTileClick(id, false)) {
                setTileClicked(false);
                console.log(`From: ${id}`)
            }
        }
        else {
            setTileClicked(true);
            if (onTileClick(id, true)) {
                setTileClicked(false);
                console.log(`From: ${id}`)
            }
            forceFalse = false;
        }
    }

    return (
        <div className='whole-tile'>
            <div className={`flex-row tile rotate-${rotationState}`}>
                <div className='flex-col flex-center'>
                    <div className={`flex-center bug ${tileConfig.left} left`}>
                    </div>
                </div>
                <div className='flex-col middle-col'>
                    <div className='flex-row'>
                        <div className={`bug ${tileConfig.top} top`}></div>
                    </div>
                    <div className='flex-row'>
                        <div className={`bug ${tileConfig.bottom} bottom`}></div>
                    </div>
                </div>
                <div className='flex-col flex-center'>
                    <div className={`flex-center bug ${tileConfig.right} right`}>
                    </div>
                </div>
            </div>
            <div className={`rotation-items ${clickedTile === id ? 'clicked' : ''}`} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
                <div className='pos-rel full-width flex-center'>
                    <div className='pos-abs full-width' onClick={handleTileClick}></div>
                    <div className='pos-abs center-items'>
                        <div className={`rotate ${hover ? 'show' : 'hide'}`} onClick={rotateLeft}>
                            L
                        </div>
                        <div className={`rotate ${hover ? 'show' : 'hide'}`} onClick={rotateRight}>
                            R
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tile