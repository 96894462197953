import React, { useState } from 'react'
import Tile from './Tile'
import './Board.css'
import '../App.css'

const Board = () => {
    const [clickedTile, setClickedTile] = useState(null);
    const [board, setBoard] = useState(Array.from(Array(9).keys()));
    

    function onTileClick(id, currentState) {
        console.log(`Current state: ${currentState}`)
        if (!currentState) {
            if (clickedTile === id) {
                setClickedTile(null);
            }
        }
        else {
            if (clickedTile === null) {
                setClickedTile(id);
                console.log(`Setting clicked tile to ${id}`);
            }
            else {
                swapTiles(clickedTile, id);
                console.log(`Swapping ${id} and ${clickedTile}`);
                return true;
            }
        }

        return false;
    }

    function swapTiles(tileA, tileB) {
        const tileAIndex = board.indexOf(tileA);
        const tileBIndex = board.indexOf(tileB);

        const tempBoard = board;
        var tempVal = tempBoard[tileAIndex];
        tempBoard[tileAIndex] = tempBoard[tileBIndex]
        tempBoard[tileBIndex] = tempVal;

        setClickedTile(null);
        setBoard([...tempBoard]);
    }

    return (
        <div className='board'>
            <div className="grid">
                {
                    board.map((element, key) => (
                        <Tile key={key} id={element} onTileClick={onTileClick} clickedTile={clickedTile}/>
                    ))
                }
            </div>
        </div>
    )
}

export default Board