export const TileConfig = {
    configs:
        [
            {
                id: 0,
                top: 'yellow tail',
                right: 'orange tail',
                bottom: 'red head',
                left: 'yellow head'
            }, 
            {
                id: 1,
                top: 'purple head',
                right: 'red head',
                bottom: 'red tail',
                left: 'orange head'
            },
            {
                id: 2,
                top: 'orange tail',
                right: 'yellow head',
                bottom: 'purple head',
                left: 'red tail'
            },
            {
                id: 3,
                top: 'red tail',
                right: 'purple head',
                bottom: 'yellow head',
                left: 'orange tail'
            },
            {
                id: 4,
                top: 'red head',
                right: 'orange head',
                bottom: 'yellow head',
                left: 'purple tail'
            },
            {
                id: 5,
                top: 'purple tail',
                right: 'yellow head',
                bottom: 'purple head',
                left: 'orange tail'
            },
            {
                id: 6,
                top: 'yellow head',
                right: 'purple head',
                bottom: 'red head',
                left: 'orange tail'
            },
            {
                id: 7,
                top: 'yellow tail',
                right: 'orange head',
                bottom: 'red head',
                left: 'purple tail'
            },
            {
                id: 8,
                top: 'purple tail',
                right: 'yellow tail',
                bottom: 'red tail',
                left: 'orange tail'
            },
        ]
}